/*body { behavior: url("/commission/css/csshover3.htc"); }*/

body {
    margin: 0;
    font-size: 13px;
    font-family: Arial;
    background-image: url('bg.gif');
}

textarea {
    font-size: 13px;
    /* border: 1px solid #d0d0d0 ; */
}

input {
    padding: 0px 1px;
    line-height: 20px;
    /* border: 1px solid #d0d0d0 ; */
}

    input.height22, select.height22 {
        height: 22px;
        line-height: 22px;
        padding: 0px 1px;
        box-sizing: border-box;
        margin: 1px 0px;
    }

h1, h2, h3, h4, h5, h6, p, ol, il, form, a {
    margin: 0;
    padding: 0;
    color: black;
    text-decoration: none;
}

.breadcrumb:hover {
    text-decoration: underline;
}

h3 {
    font-size: 13px;
}

h1 {
    font-size: 20px;
    line-height: 32px;
}

a {
    text-decoration: none;
}

div.divEnds {
    clear: both;
}

table {
    border-collapse: collapse;
}

    table td, table th {
        padding: 4px 2px;
    }

div#header {
    background: #ffc000;
    padding: 10px;
    border-bottom: 4px solid black;
}

div#headerTitle {
    float: left;
}

    div#headerTitle a {
        background: url("adminLogo.gif") no-repeat scroll 0 0 transparent;
        display: inline-block;
        height: 50px;
        padding-left: 95px;
    }

        div#headerTitle a h1 {
            margin-top: 18px;
        }

div#footer {
    background: #505050;
    padding: 15px 10px;
}

    div#footer * {
        color: white;
    }

div#greeting {
    float: right;
    padding-top: 24px;
}

    div#greeting a:hover {
        text-decoration: underline;
    }

.right {
    text-align: right;
}

legend {
    border-left: 18px solid #D3BBED;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    padding: 2px 8px 2px 6px;
    margin-bottom: 5px;
    margin-top: 5px;
}
